<template>
  <QRReader
      v-model:show="show"
      @onRead="onRead"
  />
    <van-cell-group title="扫描箱号" inset>
      <van-cell>
      <van-field
          v-model="packageCode"
          name="packageCode"
          label="箱号"
          placeholder="请扫描物料包装"
          required
          :rules="[{ required: true, message: '请扫描物料包装' }]"
          @focus="this.focusModel= 'packageCode'"
      ></van-field>
      <van-button round block type="primary" @click="this.show= true,lastShow='packageCode'" style="height: 5.33333vw">
        扫描二维码
      </van-button>
      </van-cell>

      <van-field
          v-model="materialName"
          name="materialName"
          label="物料名称"
          placeholder="请扫描物料包装箱号"
          :readonly=true

      ></van-field>
      <van-field
          v-model="materialCode"
          name="materialCode"
          label="物料编码"
          placeholder="请扫描物料包装箱号"
          :readonly=true

      ></van-field>
      <van-field
          v-model="batchNum"
          name="batchNum"
          label="批次号"
          placeholder="请扫描物料包装箱号"
          :readonly=true
      ></van-field>
      <van-field
          v-model="qty"
          name="qty"
          label="数量"
          placeholder="请扫描物料包装箱号"
          :readonly=true

      ></van-field>
      <van-field
          v-model="localWareHouseArea"
          name="localWareHouseArea"
          label="所在库区"
          placeholder="请扫描物料包装箱号"
          :readonly=true

      ></van-field>
      <van-field
          v-model="localWareHousePlace"
          name="localWareHousePlace"
          label="所在库位"
          placeholder="请扫描物料包装箱号"
          :readonly=true
      ></van-field>
      <van-field
          rows="2"
          autosize
          type="textarea"
          v-model="topTag"
          name="topTag"
          label="上级标签"
          placeholder="请扫描物料包装箱号"
          :readonly=true
      ></van-field>


    </van-cell-group>
</template>

<script>
    import ListCard from "../../components/ListCard";
    import GridList from "../../components/GridList";
    import Picker from "../../components/Picker";
    import _ from "lodash";
    import QRReader from "@/components/QRReader.vue";
    import {Toast} from "vant";

    export default {
        name: "ScanCheck",
        components: {QRReader},

        data() {
            return {
              show:false,
              packageCode:"",
              materialName:"",
              materialCode:"",
              batchNum: '',
              qty: '',
              focusModel: '',
              localWareHouseArea: '',
              localWareHousePlace: '',
              dataList: [],
              dataList1: [],
              topTag: '',
              checkNum:0,
            }
        },
        created() {
          if (!this.$sapi.isLogin()) {
            this.$sapi.handleTokenError();
            return;
          }
            this.getScanCode();
        },
        methods: {
          onRead(text) {
            var me=this;
            if(this.lastShow ==='packageCode') {
              me.packageCode = text;
              var option1 = {
                portName: 'fr.warehouse.WareHouseMaterial.getWMByPackageCodes',
                data: {'packCodeList': me.packageCode.split('5as1d51as561adasdasd6a51')},
                needLoading: true,
                successCallback: function (data) {
                  var entity = data.entities[0]
                  me.materialName = entity['material.name']
                  me.materialCode = entity['material.code']
                  me.batchNum = entity.batchNum
                  me.qty = entity.qty
                  me.localWareHousePlace = entity['wareHouseLocation.name']
                  me.localWareHouseArea = entity['wareHouseArea.name']
                  me.topTag = entity['parentCode']
                }
              };
              this.$sapi.callPort(option1);
            }
          },
            getScanCode() {
                if (!window.Capacitor) {
                    return;
                }
                var me = this;
                Capacitor.Plugins.Scanner.addListener('onScan', (data) => {
                    var barcode = data.barcode;
                    switch (me.focusModel) {
                        case 'packageCode' :
                            me.packageCode = barcode;
                            var option1 = {
                                portName: 'fr.warehouse.WareHouseMaterial.getWMByPackageCodes',
                                data: { 'packCodeList': me.packageCode.split('5as1d51as561adasdasd6a51')},
                                needLoading: true,
                                successCallback: function (data) {
                                  var entity=data.entities[0]
                                  me.materialName=entity['material.name']
                                  me.materialCode=entity['material.code']
                                  me.batchNum = entity.batchNum
                                  me.qty= entity.qty
                                  me.localWareHousePlace =entity['wareHouseLocation.name']
                                  me.localWareHouseArea =entity['wareHouseArea.name']
                                  me.topTag=entity['parentCode']
                                }
                            };
                            this.$sapi.callPort(option1);
                            break;
                    }
                })
            }
        }
    }
</script>

<style scoped>
    .item {
        display: flex;
        border-radius: 5px;
        flex-direction: row;
        box-shadow: 0 0 2px #ddd;
        align-items: center;
        padding: 10px;
        margin: 2px;
    }

    .item + .item {
        margin-top: 10px;
    }

    .item > .content {
        flex: 1;
    }

    .item .time {
        color: #666;
    }

    .van-field__label {
      flex: none;
      box-sizing: border-box;
      width: var(--van-field-label-width);
      margin-right: var(--van-field-label-margin-right);
      font-weight: 445;
      font-size: 10px;
      color: #000000;
      text-align: left;
      word-wrap: break-word;
    }
</style>